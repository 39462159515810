<template>
  <div class="error-page__content">
    <div class="error-page__content--img">
      <BaseImg src="/assets/images/components/common/maintain/404.webp" alt="404" class="404 error" />
      <span>Lỗi 404 - Không tìm thấy trang</span>
      <div class="infos">Xin lỗi quý khách! Chúng tôi không tìm thấy trang bạn đang tìm kiếm</div>
      <BaseButton class="base-button btn base-button--bg-bordeaux-fill" @click="goHome">Quay lại trang chủ</BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import BaseImg from '~/components/common/base-img.vue'
import BaseButton from '~/components/common/base-button.vue'

const router = useRouter()
const goHome = () => {
  router.push('/')
}

const toggleBodyClass = (className, add) => {
  document.body.classList[add ? 'add' : 'remove'](className)
}

onMounted(() => toggleBodyClass('fix-screen', true))
onBeforeUnmount(() => toggleBodyClass('fix-screen', false))
</script>

<style scoped lang="scss" src="~/assets/scss/page/error-mb.scss"></style>
